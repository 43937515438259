<template>
  <div
    v-if="$parent.checkActive(2) && !$parent.showCustom"
    class="notifyGroupWizardStepTwo mb-3"
  >
    <div
      v-if="$parent.successfulSent == true"
      class="loaderWrapper"
    >
      <p class="lead m--font-success">
        Messages were successfully sent.
      </p>
    </div>
    <div
      v-else-if="$parent.successfulSent == false"
      class="loaderWrapper"
    >
      <p class="lead m--font-danger">
        Messages were not sent.
      </p>
    </div>
    <div
      v-else
      class="loaderWrapper"
    >
      <div class="progress">
        <div
          class="progress-bar"
          role="progressbar"
          :style="{width: $parent.progress + '%'}"
          :aria-valuenow="$parent.progress"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{ $parent.progress + '%' }}
        </div>
      </div>
      <p class="lead m--font-info">
        Sending the messages to the recipients,<br>please wait a moment.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotifyGroupWizardStepTwo'
}
</script>

<style scoped>
.form-control.btn.btn-secondary {
  text-align: left;
}
.loaderWrapper {
  margin: auto;
  text-align: center;
}
.m-loader {
  width: 100%;
  display: inline-block;
}
.m-loader::before {
  width: 5rem;
  height: 5rem;
  margin-left: -2.5rem;
}
.lead.m--font-info {
  padding-top: 5rem;
}
</style>
